import axios from 'axios';
import { API_ROOT } from './constants';

export const axiosInstance = axios.create({
  baseURL: API_ROOT,
  timeout: 3000,
  headers: {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
