<template>
  <div>
    <!-- Visible file input -->
    <input type="file" @change="onFileChange" accept="image/*" required="required" />
    <!-- Cropper container -->
    <div v-if="imageUrl" class="cropper-container">
      <img ref="image" :src="imageUrl" alt="Cropper" />
    </div>
  </div>
</template>

<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  data() {
    return {
      cropper: null,
      imageUrl: null,
    };
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.imageUrl = URL.createObjectURL(file);
        this.$nextTick(() => {
          if (this.cropper) {
            this.cropper.destroy();
          }
          const image = this.$refs.image;
          this.cropper = new Cropper(image, {
            aspectRatio: 1, // Set your aspect ratio
            viewMode: 1,
          });
        });

        // Attach a listener to the form's submit event
        const form = document.querySelector("form");
        if (form) {
          form.addEventListener("submit", this.handleFormSubmit, { once: true });
        }
      }
    },
    handleFormSubmit(event) {
      if (this.cropper) {
        const canvas = this.cropper.getCroppedCanvas();
        const base64Image = canvas.toDataURL("image/png");

        // Convert Base64 to Blob and File
        const blob = this.dataURLtoBlob(base64Image);
        const file = new File([blob], "cropped-image.png", { type: "image/png" });

        // Update the hidden file input with the cropped file
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);

        const fileInput = document.querySelector("#logo_image_input");
        fileInput.files = dataTransfer.files;

        // Let the form submit normally
      }
    },
    dataURLtoBlob(dataURL) {
      const arr = dataURL.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  },
  beforeDestroy() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  },
};
</script>

<style>
.cropper-container {
  max-width: 100%;
  overflow: hidden;
}
</style>
